import { createI18n } from "vue-i18n/dist/vue-i18n";

export const i18n = createI18n({
  legacy: false,
  locale: "ko",
  messages: {
    ko: {
      confirm: "확인",
      cancel: "취소",
      introduce_text: "디지털 세상의 새로운 가치,",
      wallet: "지갑",
      my_wallet: "내 지갑",
      lockup: "락업",
      staking: "스테이킹",
      my_account: "내 계정",
      address: "지갑 주소",
      quantity: "수량",
      send: "전송",
      more: "더보기",
      no_list: "목록이 없습니다.",
      number: "번호",
      withdrawal_quantity: "출금 수량",
      start_date: "시작일",
      number_of_withdrawals: "출금 횟수",
      state: "상태",
      ready_service: "서비스 준비중입니다.",
      locked: "잠금",
      release: "해제",
      stop: "중지",
      complete: "완료",
      lockup_address: "락업 주소",

      // ==================== 알림 메시지 ====================
      alert_message: {
        complete_send_token: {
          title: "토큰 전송 완료",
          message: "토큰 전송이 완료되었습니다.",
        },
        complete_release_lockup: {
          title: "락업 해제 완료",
          message: "락업이 해제되었습니다.",
        },
        complete_add_lockup: {
          title: "락업 추가 완료",
          message: "락업이 추가되었습니다.",
        },
        complete_change_fee_recipient: {
          title: "수수료 수령인 변경 완료",
          message: "수수료 수령인이 변경되었습니다.",
        },
        complete_change_fee: {
          title: "수수료 변경 완료",
          message: "수수료가 변경되었습니다.",
        },
        complete_change_lockup_quantity: {
          title: "락업 수량 변경 완료",
          message: "락업 수량이 변경되었습니다.",
        },
        complete_change_lockup_release_count: {
          title: "락업 해제 횟수 변경 완료",
          message: "락업 해제 횟수가 변경되었습니다.",
        },
        complete_withdraw_token: {
          title: "토큰 출금 완료",
          message: "토큰이 출금되었습니다.",
        },
      },

      // ==================== 에러 메시지 ====================
      error_message: {
        error: {
          title: "오류",
          message: "진행중 오류가 발생했습니다.",
        },
        fail_send_token: {
          title: "토큰 전송 실패",
          message: "토큰 전송에 실패했습니다.",
        },
        fail_release_lockup: {
          title: "락업 해제 실패",
          message: "락업 해제에 실패했습니다.",
        },
        fail_add_lockup: {
          title: "락업 추가 실패",
          message: "락업 추가에 실패했습니다.",
        },
        fail_change_fee_recipient: {
          title: "수수료 수령인 변경 실패",
          message: "수수료 수령인 변경에 실패했습니다.",
        },
        fail_change_fee: {
          title: "수수료 변경 실패",
          message: "수수료 변경에 실패했습니다.",
        },
        fail_change_lockup_quantity: {
          title: "락업 수량 변경 실패",
          message: "락업 수량 변경에 실패했습니다.",
        },
        fail_change_lockup_release_count: {
          title: "락업 해제 횟수 변경 실패",
          message: "락업 해제 횟수 변경에 실패했습니다.",
        },
        fail_withdraw_token: {
          title: "토큰 출금 실패",
          message: "토큰 출금에 실패했습니다.",
        },

        empty_address: {
          title: "주소 미입력",
          message: "주소를 입력해주세요.",
        },
        empty_quantity: {
          title: "수량 미입력",
          message: "수량을 입력해주세요.",
        },
        empty_date: {
          title: "날짜 미입력",
          message: "날짜를 입력해주세요.",
        },
        empty_fee: {
          title: "수수료 미입력",
          message: "수수료를 입력해주세요.",
        },
        empty_index: {
          title: "번호 미입력",
          message: "번호를 입력해주세요.",
        },

        please_wallet_login: {
          title: "지갑 연결 필요",
          message: "지갑을 연결해주세요.",
        },
        is_not_valid_address: {
          title: "유효하지 않은 주소",
          message: "입력하신 주소가 유효하지않습니다.",
        },
        is_not_valid_date: {
          title: "유효하지 않은 날짜",
          message: "입력하신 날짜가 유효하지않습니다.",
        },
        over_time_request: {
          title: "요청 시간 만료",
          message: "요청 시간이 초과되었습니다.",
        },
      },
    },
    en: {
      confirm: "Confirm",
      cancel: "Cancel",
      introduce_text: "The new value of the digital world,",
      wallet: "Wallet",
      my_wallet: "My Wallet",
      lockup: "Lockup",
      staking: "Staking",
      my_account: "My Account",
      address: "Wallet Address",
      quantity: "Quantity",
      send: "Send",
      more: "More",
      no_list: "No list.",
      number: "Number",
      withdrawal_quantity: "Withdrawal\nQuantity",
      start_date: "Start Date",
      number_of_withdrawals: "Number of\nWithdrawals",
      state: "State",
      ready_service: "Service is under preparation.",
      locked: "Locked",
      release: "Release",
      stop: "Stop",
      complete: "Complete",
      lockup_address: "Lockup Address",

      // ==================== Alert Message ====================
      alert_message: {
        complete_send_token: {
          title: "Token send complete",
          message: "Token send complete.",
        },
        complete_release_lockup: {
          title: "Lockup release complete",
          message: "Lockup release complete.",
        },
        complete_add_lockup: {
          title: "Lockup add complete",
          message: "Lockup add complete.",
        },
        complete_change_fee_recipient: {
          title: "Change fee recipient complete",
          message: "Change fee recipient complete.",
        },
        complete_change_fee: {
          title: "Change fee complete",
          message: "Change fee complete.",
        },
        complete_change_lockup_quantity: {
          title: "Change lockup quantity complete",
          message: "Change lockup quantity complete.",
        },
        complete_change_lockup_release_count: {
          title: "Change lockup release count complete",
          message: "Change lockup release count complete.",
        },
        complete_withdraw_token: {
          title: "Withdraw token complete",
          message: "Withdraw token complete.",
        },
      },

      // ==================== Error Message ====================
      error_message: {
        error: {
          title: "Error",
          message: "An error occurred while processing.",
        },
        fail_send_token: {
          title: "Token send fail",
          message: "Token send fail.",
        },
        fail_release_lockup: {
          title: "Lockup release fail",
          message: "Lockup release fail.",
        },
        fail_add_lockup: {
          title: "Lockup add fail",
          message: "Lockup add fail.",
        },
        fail_change_fee_recipient: {
          title: "Change fee recipient fail",
          message: "Change fee recipient fail.",
        },
        fail_change_fee: {
          title: "Change fee fail",
          message: "Change fee fail.",
        },
        fail_change_lockup_quantity: {
          title: "Change lockup quantity fail",
          message: "Change lockup quantity fail.",
        },
        fail_change_lockup_release_count: {
          title: "Change lockup release count fail",
          message: "Change lockup release count fail.",
        },
        fail_withdraw_token: {
          title: "Withdraw token fail",
          message: "Withdraw token fail.",
        },

        empty_address: {
          title: "Empty address",
          message: "Please enter the address.",
        },
        empty_quantity: {
          title: "Empty quantity",
          message: "Please enter the quantity.",
        },
        empty_date: {
          title: "Empty date",
          message: "Please enter the date.",
        },
        empty_fee: {
          title: "Empty fee",
          message: "Please enter the fee.",
        },
        empty_index: {
          title: "Empty index",
          message: "Please enter the index.",
        },

        please_wallet_login: {
          title: "Wallet connection required",
          message: "Please connect your wallet.",
        },
        is_not_valid_address: {
          title: "Invalid address",
          message: "The address you entered is invalid.",
        },
        is_not_valid_date: {
          title: "Invalid date",
          message: "The date you entered is invalid.",
        },
        over_time_request: {
          title: "Request time expired",
          message: "The request time has expired.",
        },
      },
    },
    zh: {},
    ja: {},
  },
});
