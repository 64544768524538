import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import { i18n } from "./assets/js/i18n";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

require("./assets/css/style.css");

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
app.config.globalProperties.$axios = axios;

app.use(pinia).use(router).use(i18n).mount("#app");
